// Bootstrap Required
@import "node_modules/bootstrap/scss/functions";
@import "variables";
@import "node_modules/bootstrap/scss/mixins";

// Bootstrap Optional
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
//@import "node_modules/bootstrap/scss/type";
//@import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
//@import "node_modules/bootstrap/scss/tables";
//@import "node_modules/bootstrap/scss/forms";
//@import "node_modules/bootstrap/scss/buttons";
//@import "node_modules/bootstrap/scss/transitions";
//@import "node_modules/bootstrap/scss/dropdown";
//@import "node_modules/bootstrap/scss/button-group";
//@import "node_modules/bootstrap/scss/input-group";
//@import "node_modules/bootstrap/scss/custom-forms";
//@import "node_modules/bootstrap/scss/nav";
//@import "node_modules/bootstrap/scss/navbar";
//@import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
//@import "node_modules/bootstrap/scss/badge";
//@import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/list-group";
//@import "node_modules/bootstrap/scss/close";
//@import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/utilities";
// @import "node_modules/bootstrap/scss/print";

@import "header";
//@import "footer";
//@import "list-items/list-items";
//@import "list-items/item";
@import "custom-gallery";


@media (max-device-width: 1024px) and (orientation: portrait){
    body {
        width: 100vh;
        height: 100vw;
        transform-origin: 0 0;
        transform: rotateZ(90deg) translate3d(0%,-100%,0);
    }
}

html{
    font-size: 16px;
}

body{
    -webkit-font-smoothing: antialiased;
    background-color: white;
    touch-action: manipulation;
}

img{
    max-width: 100%;
    max-height: 100%;
}

.object-fit-cover{
    object-fit: cover;
}

.object-fit-contain{
    object-fit: contain;
}

.mh-75{
    max-height: 75%;
}
.mh-50{
    max-height: 50%;
}