#header{
    position: fixed;
    display: block;
    z-index: 99;
    width: 100%;
    top: 0; left: 0;
    user-select: none;
    padding: 30px 30px;
    //letter-spacing: 0.03em;
    line-height: 1.4em;
    color: black;
    cursor: pointer;
    font-weight: 400;
    transition-property: opacity;
    transition-duration: 200ms;
    #logo{
        font-size: 1rem;
        font-weight: inherit;
        a{
            color: inherit;
            text-decoration: none;
        }
    }
    #info{
        position: relative;
        display: none;
        color: inherit;
        font-size: 1rem;
        a{
            text-decoration: none;
            color: inherit;
            &:hover{
                color: rgba(0,0,0,0.5);
            }
        }
    }
}

@media (min-device-width: 1024px){
    body.-hide-menu #header{
        opacity: 0;
    }
}

body.open-info{
    #header{
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.95);
        @supports ((-webkit-backdrop-filter: blur(30px)) or (backdrop-filter: blur(30px))) {
            background-color: rgba(255, 255, 255, 0.7) !important;
            -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
        }
        #info{
            display: block;
        }
    }
}

[data-js-toggle-info]{
    cursor: pointer;
}