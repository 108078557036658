.custom-gallery{
    overflow: hidden;
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    .custom-gallery-slide{
        visibility: hidden;
        pointer-events: none;
        user-select: none;
        &.active{
            visibility: visible;
            pointer-events: initial;
        }
    }
}

.prevBtn,
.nextBtn{
    position: absolute;
    z-index: 9;
    top: 0;
    width: 50%; 
    height: 100%;
    opacity: 0;
}
.prevBtn{
    cursor: w-resize;
    left: 0;
    width: 25%;
}
.nextBtn{
    right: 0;
    width: 75%;
    cursor: e-resize;
}